<style scoped>
.warna{
    color:white
}
td {
  text-align: justify;
}
th {
    background-color:#EBEDF3
}
</style>
<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header border-0">
                <div class="card-title">
                <h3 class="card-label">Data Rekomendasi PPB KL</h3>
                </div>
            </div>
            <div class="card-body pt-0">
                <div class="row mb-2">
                    <div class="col-lg-6">
                        <b-button :href="this.url+'/rekomendasi_peserta?id_peserta='+user.id_ptsp+'&tipe=ppb_kl&export=yes&tahun=2023'" variant="primary"><i class="fa fa-download" aria-hidden="true"/>Excel </b-button>
                    </div>
                    <div class="col-lg-6"></div>
                </div>
                <br>
                <div class="table-responsive">
                    <table style="border-collapse: collapse;" class="table">
                        <thead>
                            <tr>
                                <th>Kriteria Penilaian</th>
                                <th>Indikator Penilaian</th>
                                <th>Tolok Ukur</th>
                                <th>Hasil Verifikasi</th>
                                <th>Rekomendasi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="8">{{datarekomendasi[0].kriteria}}</td>
                                <td rowspan="4">{{datarekomendasi[0].indikator[0].indikator}}</td>
                                <td>{{datarekomendasi[0].indikator[0].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[0].color'>{{datarekomendasi[0].indikator[0].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[0].color'>{{datarekomendasi[0].indikator[0].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[0].indikator[0].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[1].color'>{{datarekomendasi[0].indikator[0].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[1].color'>{{datarekomendasi[0].indikator[0].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[0].indikator[0].pertanyaan[2].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[2].color'>{{datarekomendasi[0].indikator[0].pertanyaan[2].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[2].color'>{{datarekomendasi[0].indikator[0].pertanyaan[2].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[0].indikator[0].pertanyaan[3].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[2].color'>{{datarekomendasi[0].indikator[0].pertanyaan[3].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[0].pertanyaan[2].color'>{{datarekomendasi[0].indikator[0].pertanyaan[3].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td rowspan="2">{{datarekomendasi[0].indikator[1].indikator}}</td>
                                <td>{{datarekomendasi[0].indikator[1].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[1].pertanyaan[0].color'>{{datarekomendasi[0].indikator[1].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[1].pertanyaan[0].color'>{{datarekomendasi[0].indikator[1].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[0].indikator[1].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[1].pertanyaan[1].color'>{{datarekomendasi[0].indikator[1].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[1].pertanyaan[1].color'>{{datarekomendasi[0].indikator[1].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td rowspan="2">{{datarekomendasi[0].indikator[2].indikator}}</td>
                                <td>{{datarekomendasi[0].indikator[2].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[2].pertanyaan[0].color'>{{datarekomendasi[0].indikator[2].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[2].pertanyaan[0].color'>{{datarekomendasi[0].indikator[2].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[0].indikator[2].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[2].pertanyaan[1].color'>{{datarekomendasi[0].indikator[2].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[0].indikator[2].pertanyaan[1].color'>{{datarekomendasi[0].indikator[2].pertanyaan[1].rekomendasi}}</td>
                            </tr>


                            <tr>
                                <td rowspan="7">{{datarekomendasi[1].kriteria}}</td>
                                <td rowspan="2">{{datarekomendasi[1].indikator[0].indikator}}</td>
                                <td>{{datarekomendasi[1].indikator[0].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[0].pertanyaan[0].color'>{{datarekomendasi[1].indikator[0].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[0].pertanyaan[0].color'>{{datarekomendasi[1].indikator[0].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[1].indikator[0].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[0].pertanyaan[1].color'>{{datarekomendasi[1].indikator[0].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[0].pertanyaan[1].color'>{{datarekomendasi[1].indikator[0].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td rowspan="4">{{datarekomendasi[1].indikator[1].indikator}}</td>
                                <td>{{datarekomendasi[1].indikator[1].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[0].color'>{{datarekomendasi[1].indikator[1].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[0].color'>{{datarekomendasi[1].indikator[1].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[1].indikator[1].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[1].color'>{{datarekomendasi[1].indikator[1].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[1].color'>{{datarekomendasi[1].indikator[1].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[1].indikator[1].pertanyaan[2].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[2].color'>{{datarekomendasi[1].indikator[1].pertanyaan[2].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[2].color'>{{datarekomendasi[1].indikator[1].pertanyaan[2].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[1].indikator[1].pertanyaan[3].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[3].color'>{{datarekomendasi[1].indikator[1].pertanyaan[3].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[1].pertanyaan[3].color'>{{datarekomendasi[1].indikator[1].pertanyaan[3].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[1].indikator[2].indikator}}</td>
                                <td>{{datarekomendasi[1].indikator[2].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[2].pertanyaan[0].color'>{{datarekomendasi[1].indikator[2].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[1].indikator[2].pertanyaan[0].color'>{{datarekomendasi[1].indikator[2].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td rowspan="11">{{datarekomendasi[2].kriteria}}</td>
                                <td rowspan="3">{{datarekomendasi[2].indikator[0].indikator}}</td>
                                <td>{{datarekomendasi[2].indikator[0].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[0].pertanyaan[0].color'>{{datarekomendasi[2].indikator[0].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[0].pertanyaan[0].color'>{{datarekomendasi[2].indikator[0].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[2].indikator[0].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[0].pertanyaan[1].color'>{{datarekomendasi[2].indikator[0].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[0].pertanyaan[1].color'>{{datarekomendasi[2].indikator[0].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[2].indikator[0].pertanyaan[2].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[0].pertanyaan[2].color'>{{datarekomendasi[2].indikator[0].pertanyaan[2].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[0].pertanyaan[2].color'>{{datarekomendasi[2].indikator[0].pertanyaan[2].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td rowspan="4">{{datarekomendasi[2].indikator[1].indikator}}</td>
                                <td>{{datarekomendasi[2].indikator[1].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[0].color'>{{datarekomendasi[2].indikator[1].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[0].color'>{{datarekomendasi[2].indikator[1].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[2].indikator[1].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[1].color'>{{datarekomendasi[2].indikator[1].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[1].color'>{{datarekomendasi[2].indikator[1].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[2].indikator[1].pertanyaan[2].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[2].color'>{{datarekomendasi[2].indikator[1].pertanyaan[2].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[2].color'>{{datarekomendasi[2].indikator[1].pertanyaan[2].rekomendasi}}</td>
                            </tr>
                             <tr>
                                <td>{{datarekomendasi[2].indikator[1].pertanyaan[3].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[3].color'>{{datarekomendasi[2].indikator[1].pertanyaan[3].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[1].pertanyaan[3].color'>{{datarekomendasi[2].indikator[1].pertanyaan[3].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td rowspan="2">{{datarekomendasi[2].indikator[2].indikator}}</td>
                                <td>{{datarekomendasi[2].indikator[2].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[2].pertanyaan[0].color'>{{datarekomendasi[2].indikator[2].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[2].pertanyaan[0].color'>{{datarekomendasi[2].indikator[2].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[2].indikator[2].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[2].pertanyaan[1].color'>{{datarekomendasi[2].indikator[2].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[2].pertanyaan[1].color'>{{datarekomendasi[2].indikator[2].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td rowspan="2">{{datarekomendasi[2].indikator[3].indikator}}</td>
                                <td>{{datarekomendasi[2].indikator[3].pertanyaan[0].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[3].pertanyaan[0].color'>{{datarekomendasi[2].indikator[3].pertanyaan[0].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[3].pertanyaan[0].color'>{{datarekomendasi[2].indikator[3].pertanyaan[0].rekomendasi}}</td>
                            </tr>
                            <tr>
                                <td>{{datarekomendasi[2].indikator[3].pertanyaan[1].pertanyaan}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[3].pertanyaan[1].color'>{{datarekomendasi[2].indikator[3].pertanyaan[1].jawaban}}</td>
                                <td class="warna" :style='"background-color:"+ datarekomendasi[2].indikator[3].pertanyaan[1].color'>{{datarekomendasi[2].indikator[3].pertanyaan[1].rekomendasi}}</td>
                            </tr>
                        </tbody>
                        </table>
                </div>
                <!--begin::Example-->
                
                <!--end::Example-->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import Swal from "sweetalert2";
import Vue from "vue";
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})
// @import "vue-select/src/scss/vue-select.scss";

export default {
  name: "Rekomendasi-PPB",
  components: {vSelect},
  data() {
    return {
    url: localStorage.getItem('baseapi'),
    token: localStorage.getItem('id_token'),
    user: JSON.parse(localStorage.getItem('user')),
    tanggal: localStorage.getItem('tanggal'),
    datarekomendasi:[]
    };
  },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Rekomendasi PPB K/L" }]);

  },
  methods: {
    loaddata(){
        this.$loading(true);
        axios.get(this.url+`/rekomendasi_peserta?id_peserta=`+this.user.id_ptsp+`&tipe=ppb_kl&export=no&tahun=2023`, {
              headers: {
                  'xth': this.token
              }
            }).then(response => {
            this.$loading(false);
            this.datarekomendasi = response.data.data
            console.log(response.data.data)
        }).catch(error=>{
            this.$loading(false);
            console.log(error)
            return error
            })
    },
    
  },
  created(){
      this.loaddata();
  }
};
</script>